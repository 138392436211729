MyCalendarController.$inject = ['logService', '$scope', '$localStorage', 'allevaApi', '$timeout', 'noty', '$state', '$location'];
export default function MyCalendarController(logService, $scope, $localStorage, allevaApi, $timeout, noty, $state, $location) {

  /***************************
   * Params
   **************************/
  // Page
  $scope.setPageTitle("Alleva Family - My Calendar");

  // UI
  $scope.myCalendarLoaded = false;

  // Buttons

  // Data

  // Page

  /***************************
   * Init
   **************************/
  $scope.initMyCalendar = () => {
    $('#mycalendar')[0].addEventListener('load', function() {
      $scope.$apply(() => {
        $scope.myCalendarLoaded = true; 

        document.getElementById("mycalendar").style.height = "100vh";

      });
    }, true);
  };

  /***************************
   * Actions
   **************************/
 

  /***************************
   * CRUD
   **************************/
  // GET

  // POST
 
  /***************************
   * Helpers
   **************************/

}
