/*jshint esversion: 6 */
SiteStyles.$inject = ['$timeout', 'logService','$localStorage','allevaApi'];
export default function SiteStyles($timeout, logService, $localStorage, allevaApi) {

    const set = (rehabName, styles) => {
        let root = document.documentElement;

        styles.forEach(style => {
            if(style.isColorValue){
                root.style.setProperty(style.propertyKey, style.propertyValue);
            }
            else if(style.isImageValue){
                const url = `${ALLEVA_STORAGE_HOST}/${rehabName}${ALLEVA_STYLE_IMAGES}${style.propertyValue}`;
                let styleValue ="url('" + url + "')";
                root.style.setProperty(style.propertyKey, styleValue);
                style.url = url;
            }
        });
    };

    return {
        styles: {
            getAndSet(rehabName, preloadedStylesCompletion) {
                if($localStorage.styles){
                    set(rehabName, $localStorage.styles);
                    preloadedStylesCompletion();
                }

                return allevaApi.FamilyAPI.getStyles({"rehabName": rehabName})
                .then(styles => {
                    set(rehabName, styles);
                    $localStorage.styles = styles;

                    logService.console.info("Styles", styles);

                    return Promise.resolve();
                })
                .catch((error) => {
                  logService.console.error(error);
                  return Promise.reject(error);

                });
            }
        }
    };
}
