ResetPasswordController.$inject = ['logService', '$scope', '$localStorage', 'allevaApi', '$timeout', 'noty', '$state', '$location'];
export default function ResetPasswordController(logService, $scope, $localStorage, allevaApi, $timeout, noty, $state, $location) {

  /***************************
   * Params
   **************************/
  // Page
  $scope.setPageTitle("Alleva Family - Reset Password");

  // UI

  // Buttons

  // Data
  $scope.userName  = null;
  $scope.userEmail = null;
  $scope.userToken = null;

  $scope.passwordForm = {};

  // Page

  /***************************
   * Init
   **************************/
  $scope.initReset = () => {
    
    if($scope.isUserLoggedIn()){
      $scope.logout();
      return;
    }
    
    $scope.isLoading = true;

    if ($location.search().email && $location.search().token) {
      logService.console.info($location.search().email, $location.search().token);
      $scope.userEmail = decodeURIComponent($location.search().email);
      $scope.userToken = decodeURIComponent($location.search().token);

      $scope.userToken = $scope.userToken.split(' ').join('+');  // fix issue where %20 is decoded into a space and not a "+""
      $scope.userName  = decodeURIComponent($location.search().name) == undefined || ' ' ? null : decodeURIComponent($location.search().name);

      $scope.isLoading = false;

    } else {
      $scope.isLoading = false;
    }
  };

  /***************************
   * Actions
   **************************/
  $scope.resetPWSubmit = (form) => {
    noty.closeAll();

    // Validate Form
    var valid = true;
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    //Validate PWs
    if ($scope.passwordForm.password.length < 8) {
      let alert = "<p>Passwords must be at least <b class='noty-bold'>8 characters</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[a-z]/.test($scope.passwordForm.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one lowercase (a-z)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[A-Z]/.test($scope.passwordForm.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one uppercase (A-Z)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[0-9]/.test($scope.passwordForm.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one digit (0-9)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }
    if (!(/[~`!#$%\^&*+=\-\[\]\\';@,/{}|\\":<>\?]/g.test($scope.passwordForm.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one non-alphanumeric character</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!valid) {
      return false;
    }

    if ($scope.passwordForm.password != $scope.passwordForm.cPassword) {
      let alert = "<h5>Passwords must match.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      $scope.invalidPW = true;
      return false;
    }

    $scope.isLoading = true;

    $scope.resetPassword()
      .then(result => {
        $scope.isLoading = false;

        $scope.passwordResetSuccess = true;
      })
      .catch(errors => {
        $scope.isLoading = false;
      });
  };

  /***************************
   * CRUD
   **************************/
  // GET

  // POST
  $scope.resetPassword = () => {
    let params = {
      "email"         : $scope.userEmail,
      "token"         : $scope.userToken,
      "password"      : $scope.passwordForm.password,
      "rehabSubdomain": $scope._Subdomain
    };

    return allevaApi.Auth.resetPassword(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
  /***************************
   * Helpers
   **************************/

}
