FamilyPortalApp.$inject = ['siteStyle','logService', '$scope', '$localStorage', '$state', 'allevaApi', 'noty'];
export default function FamilyPortalApp(siteStyle, logService, $scope, $localStorage, $state, allevaApi, noty) {

  /***************************
   * Init
   **************************/
  // Globals
  $scope.authUser    = $localStorage.authUser;
  $scope.currentUser = $localStorage.currentUser;

  $scope.rehabDetails  = $localStorage.rehabDetails;
  $scope.currentClient = null;

  $scope.summernoteOptions = {};

  // Page
  $scope.pageTitle      = "Alleva Family Login";
  $scope.showLoginArea  = $localStorage.authUser ? false : true;
  $scope.showLockedPage = $localStorage.authUser ? $localStorage.authUser.locked ? true : false : false;

  // Style
  $scope.stylesLoaded = false;

  // Nav
  $scope.menuItemClicked      = false;
  $scope.isDashboardPage      = false;
  $scope.isMyApplicationsPage = false;

  // Roles info
  $scope._Roles = {};

  // Constants
  $scope._BlobStorageUrl      = `${ALLEVA_STORAGE_HOST}/`;
  $scope._Subdomain           = window.location.host.split('.')[1] ? window.location.host.split('.')[0]: false;
  $scope._GenderCodeMale   = '1017';
  $scope._GenderCodeFemale = '1018';
  $scope._GenderCodeNoPref = '1019';

  $scope._PageDashboard     = 1;
  $scope._PageMyApplication = 2;

  /***************************
   * Actions
   **************************/
  $scope.setPageTitle = (title) => {
    $scope.pageTitle = title;
  };

  $scope.initApp = () => {

    $scope.isLoadingClient = false;

    // Get CSS properties
    siteStyle.styles.getAndSet($scope._Subdomain, () => {
      // has preloaded styles available
      $scope.stylesLoaded = true;
    })
    .finally(() => {
      // finish getting styles from db
      $scope.stylesLoaded = true;
    });

    // Get Rehab info
    $scope.getRehabDetails()
    .then(result => {
      $localStorage.rehabDetails = result;
      $scope.rehabDetails        = result;
      logService.console.info("rehabDetails", $localStorage.rehabDetails);

    })
    .catch((error) => {
      logService.console.error(error);
    });

    // Get Roles info
    $scope.getRoles()
    .then(roles => {
      $scope._Roles = roles;

      logService.console.info("Roles", roles);

      if($scope.currentUser){
        // Set role name on the current user
        $scope.currentUser.roleObj = $scope._Roles.find(role => role.id === $scope.currentUser.role) || {};
      }

      // Get logged in user info.
      if($localStorage.authUser){
        $scope.getCurrentUserDetails();
      }

    });

    // Get Current Client
    $scope.setCurrentClient();

  };

  $scope.logout = () => {
    let styleCopy = $localStorage.styles;
    $localStorage.$reset();
    $scope.authUser      = null;
    $scope.currentUser   = null;
    $scope.currentClient = null;

    $state.go('login', {});
    $scope.showLoginArea = true;

    $localStorage.styles = styleCopy;
  };

  $scope.login = (completion = false) => {
    $scope.getCurrentUserDetails((success) => {
      if(success){

        var hasClient = false;

        $scope.currentUser.clients.forEach(client => {
          if(client.isClient){
              hasClient = true;
          }
        });

        if(hasClient){
          $state.go('home', {});
        }
        else{
          $state.go('myApplications', {});
        }
        $scope.showLoginArea  = false;
        $scope.showLockedPage = false;
      }
      if(completion){
        completion();
      }
    });
  };

  $scope.locked = () => {
    $localStorage.authUser.locked = true;
    $scope.showLockedPage         = true;
  };

  $scope.getCurrentUserDetails = (completion, withChecklist = false) => {
    if($scope.currentUser) { $scope.currentUser.checklistLoaded = false; }
    $scope.getCurrentUser(withChecklist)
    .then(user => {

      // Check for user availability here
      if(!user) {
        $scope.logout();
        let alert = "<h5>Account Inactive</h5><p>Your account has been deactivated. Please contact support.</p>";
        new noty({
          text: alert
        }).show();
        if(completion){ completion(false); } // send completion callback\
        return;
      }

      if(user.applicationUserId !== $localStorage.authUser.id){ // The Auth user and the Portal user ID's don't match
        $scope.logout();
        let alert = "<h5>Authentication Failed</h5><p>Please contact support. Error code: 89.</p>";
        new noty({
          text   : alert,
          timeout: false
        }).show();
        if(completion){ completion(false); } // send completion callback\
        return;
      }

      // Set role name
      user.roleObj = $scope._Roles.find(role => role.id === user.role) || {};

      // set avatar
      user.clients.forEach(currentClient => {
        if(currentClient && currentClient.documentFilePath){
          currentClient.avatarUrl = allevaApi.FamilyAPI.getClientImagePath(currentClient.documentFilePath);
        }
        else{
          currentClient.avatarUrl = null;
        }
      });

      $localStorage.currentUser          = user;
      $scope.currentUser                 = user;
      $scope.currentUser.checklistLoaded = withChecklist;
      logService.console.info("currentUser", $localStorage.currentUser);

      if(!$scope.rehabDetails.facilitySettings){ // now that we have a user, get more info like facility settings
        $scope.getRehabDetails().then((result) => {
          $localStorage.rehabDetails = result;
          $scope.rehabDetails        = result;
          logService.console.info("rehabDetails-update", $localStorage.rehabDetails);
        }).catch();
      }

      if(completion){ completion(true); } // send completion callback

      if(!withChecklist){
        // Get user client checklists
        $scope.getCurrentUser(true)
        .then(checklist => {

          // update checklist
          $scope.currentUser.clients.forEach(client => {
            var checklistForClient = checklist.clients.filter(chklst => chklst.leadId == client.leadId);
            if(checklistForClient[0] && checklistForClient[0].checklist){
              client.checklist = checklistForClient[0].checklist;
            }
          });

          $scope.currentUser.checklistLoaded = true;

        });
      }

    })
    .catch((error) => {

      if(completion){ completion(false); } // send completion callback

      switch(error.status) {
        case 401:   // Unauthorized
            $scope.logout();
            break;
        }

        if (typeof(error) === 'string' || error instanceof String) {
          new noty({
            text   : error,
            timeout: 10000
          }).show();
        }

    });
  };

  $scope.setCurrentClient = (client) => {
    if(!$scope.currentUser){ return; }
    if(client){
      $scope.currentClient = client;
    }
    else if($localStorage.currentClient){
      $scope.currentClient = $localStorage.currentClient;
    }
    else{
      // Get first active client on the list
      $scope.currentUser.clients.every(aClient => {
        if(aClient.isClient){
          $scope.currentClient = aClient;
          return false; // break from for loop
        }
        return true;
      });

    }

    $localStorage.currentClient = $scope.currentClient;

    // set avatar
    if($scope.currentClient && $scope.currentClient.documentFilePath){
      $scope.currentClient.avatarUrl = allevaApi.FamilyAPI.getClientImagePath($scope.currentClient.documentFilePath);
    }
    else if($scope.currentClient){
      $scope.currentClient.avatarUrl = null;
    }
  };

  /***************************
   * CRUD
   **************************/
  // GET
  $scope.getRehabDetails = () => {
    var stringParams = {
      "rehabName": $scope._Subdomain
    };

    if($scope.currentUser){
      stringParams.facilityId = $scope.currentUser.facilityId;
    }

    return allevaApi.CommonAPI.rehabDetails(stringParams)
      .then((response) => {
        if (response) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      });
  };

  $scope.getRoles = () => {
    var stringParams = {
      "subDomain": $scope._Subdomain
    };

    return allevaApi.Auth.getAllRoles(stringParams)
      .then((response) => {
        if (response) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      });
  };

  $scope.getCurrentUser = (checklist = false) => {

    if(!$localStorage.authUser){ // doesn't have an auth user -- relog
      $scope.logout();
      return Promise.reject("Session Expired. Please login again.");
    }

    let stringParams = {
      "applicationUserId": $localStorage.authUser.id,
      "getCheckList"     : checklist
    };

    return allevaApi.FamilyAPI.currentUserDetails(stringParams)
      .then((response) => {
        if (response.length) {
          return Promise.resolve(response[0]);
        }
        return Promise.resolve(null);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.getCountries = () => {
    return allevaApi.CommonAPI.countries()
      .then((response) => {
          return Promise.resolve(response);
      });
  };

  $scope.getStates = (countryCode) => {
    return allevaApi.CommonAPI.states(countryCode)
      .then((response) => {
          return Promise.resolve(response);
      });
  };

  $scope.getRelationships = () => {
    return allevaApi.CommonAPI.relationships()
      .then((response) => {
          return Promise.resolve(response);
      });
  };

  /***************************
     * Nav
   **************************/
  $scope.toggleMenu = () => {

    var offcanvasVisible = $('body').hasClass('offcanvas-left-expanded');
		var menubarExpanded = $('#menubar').data('expanded');
		$scope.menuItemClicked = !$scope.menuItemClicked;

		// Check if the menu should open
		if (($scope.menuItemClicked === true || offcanvasVisible === false) && menubarExpanded !== true) {
			// Add listener to close the menubar
			$('#content').one('mouseover', function (e) {
				$('body').removeClass('menubar-visible');

          // Don't close the menus when it is pinned on large viewports
          if (window.materialadmin.App.minBreakpoint('md')) {
            if ($('body').hasClass('menubar-pin')) {
              return;
            }
          }
          $('#menubar').data('expanded', false);
			});

			// Add open variables
			$('body').addClass('menubar-visible');
			$('#menubar').data('expanded', true);

			// Triger enter event
      $('#menubar').triggerHandler('enter');
    }
    else{
      $('body').removeClass('menubar-visible');

          // Don't close the menus when it is pinned on large viewports
          if (window.materialadmin.App.minBreakpoint('md')) {
            if ($('body').hasClass('menubar-pin')) {
              return;
            }
          }
          $('#menubar').data('expanded', false);
    }

  };

  $scope.activeNav = (navItem) => {
    $scope.resetNav();

    switch (navItem) {
      case $scope._PageDashboard:
          $scope.isDashboardPage = true;
          break;
      case $scope._PageMyApplication:
          $scope.isMyApplicationsPage = true;
          break;
      default:
        break;
    }
  };

  $scope.resetNav = () => {
    $scope.isDashboardPage      = false;
    $scope.isMyApplicationsPage = false;
  };

  /***************************
   * Helpers
   **************************/
  $scope.classroomGuardiansEnabled = () => {
    if($scope.rehabDetails.facilitySettings){
      return $scope.rehabDetails.facilitySettings.classroomGuardiansEnabled;
    }
    return false;
  };

  $scope.isClientRole = (roleObj) => {
    return roleObj.name == "Participant"
  };

  $scope.getCompanyLogo = () => {
    if($localStorage.styles){
      let imgStyle = $localStorage.styles.find(style => style.propertyKey == '--main-primary-company-logo');
      if(imgStyle){
        return imgStyle.url;
      }
    }
  };

  $scope.isUserLoggedIn = () => {
    return $localStorage.authUser;
  };

  $scope.formatPhone = (model) => {
    var cleaned = ('' + model).replace(/\D/g, '');
    var match   = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode  = (match[1] ? '+1 ' : '');
      var formatted = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      return formatted;
    }
    return cleaned;
  };

}
