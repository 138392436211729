
/*jshint esversion: 6 */
// controllers
import HomeController from './controllers/Home.controller';
import MyApplicationsController from './controllers/MyApplications.controller';

routing.$inject = ['$stateProvider', '$urlServiceProvider', '$localStorageProvider'];

export default function routing($stateProvider, $urlServiceProvider, $localStorage) {
    
    const index = {
        name: 'index',
        url: '/',
        views: {
            "login" : {
                template: require('./views/login.html')
            }
        }
    };

    const locked = {
        name: 'locked',
        url: '/locked', 
        views: {
            "locked" : {
                template: require('./views/locked.html')
            }
        },
        onEnter: AllevaRouter
    };

    const login = {
        name: 'login',
        url: '/login',
        views: {
            "login" : {
                template: require('./views/login.html')
            }
        }
    };

    const resetpassword = {
        name: 'resetpassword',
        url: '/resetpassword',
        views: {
            "resetpassword" : {
                template: require('./views/resetpassword.html')
            }
        }
    };

    const home = {
        name: 'home',
        url: '/dashboard', 
        template: require('./views/home.html'),
        controller: HomeController,
        onEnter: AllevaRouter
    };

    const myApplications = {
        name: 'myApplications',
        url: '/applications', 
        template: require('./views/my-applications.html'),
        controller: MyApplicationsController,
        onEnter: AllevaRouter
    };

    const notFound = {
        name: '404',
        url: '/404', 
        template: require('./views/404.html'),
        onEnter: AllevaRouter
    };

    const comingsoon = {
        name: 'comingsoon',
        url: '/comingsoon', 
        template: require('./views/coming-soon.html'),
        onEnter: AllevaRouter
    };

    const myCalendar = {
        name: 'mycalendar',
        url: '/mycalendar', 
        template: require('./views/my-calendar.html'),
        onEnter: AllevaRouter
    };

    function AllevaRouter() { // Middleware: Checks to see if route/page should be viewable
        var authUser = $localStorage.get('authUser');
        if (!authUser) {
            $stateProvider.stateService.go('login', {});
        }
        else {
            if (authUser.locked) {
                $stateProvider.stateService.go('locked', {});
            }
        }
    }

    $stateProvider.state(index);
    $stateProvider.state(locked);
    $stateProvider.state(login);
    $stateProvider.state(resetpassword);
    $stateProvider.state(home);
    $stateProvider.state(myApplications);
    $stateProvider.state(notFound);
    $stateProvider.state(comingsoon);
    $stateProvider.state(myCalendar);
    //$locationProvider.html5Mode(true);

    // Default path if nothing is matched. State would become 'landing'
    $urlServiceProvider.rules.otherwise('/login');
}