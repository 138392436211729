LoginController.$inject = ['logService', '$scope', '$localStorage', 'allevaApi', '$timeout', 'noty', '$state', '$location'];
export default function LoginController(logService, $scope, $localStorage, allevaApi, $timeout, noty, $state, $location) {

  /***************************
   * Params
   **************************/
  // Page
  $scope.setPageTitle("Alleva Family - Login");

  // UI
  $scope.isLoading = false;
  $scope.loggingIn = false;
  $scope.signingUp = false;

  $scope.isLogin                 = true;
  $scope.isLoginCredsInvalid     = false;
  $scope.isSignup                = false;
  $scope.isUserRole              = false;
  $scope.isSignupConfirmation    = false;
  $scope.isSelectFacility        = false;
  $scope.isForgotPassword        = false;
  $scope.isForgotPasswordConfirm = false;
  $scope.isForgotUsername        = false;
  $scope.isForgotUsernameConfirm = false;

  $scope.facilityId = 0;

  // Buttons
  $scope.forMyselfSelected    = false;
  $scope.familyMemberSelected = false;

  // Data
  $scope.loginInfo = {
    subdomain: $scope._Subdomain
  };
  $scope.signupInfo     = {};
  $scope.signupUser     = null;
  $scope.forgotPassword = {};
  $scope.forgotUsername = {};

  // Page
  $scope.loginForms = {
    loginForm : null,
    signupForm: null
  };

  /***************************
   * Init
   **************************/
  $scope.initLogin = () => {

    $scope.isLoading = false;

    if ($location.search().facility) {
      $scope.signupNextClick();
    }

    var authUser = $localStorage.authUser;
    if (authUser) {
      $state.go('home', {});
    }
  };

  /***************************
   * Actions
   **************************/
  $scope.loginClick = (isRelog = false) => {

    // Validate Form
    if (!isRelog && $scope.login.loginForm.$invalid) {
      return;
    }

    $scope.isLoading = true;

    $scope.loginUser()
      .then(response => {
        $scope.isLoginCredsInvalid = false;
        $localStorage.authUser     = response;
        $localStorage.access_token = response.token;
        logService.console.info("authUser", $localStorage.authUser);

        if (isRelog) {
          let alert = "<p>Successfully logged back in.</p>";
          new noty({
            text: alert,
            type: 'success'
          }).show();
        }

        // Go to Home Page
        $scope.login(() => {
          $scope.isLoading = false;
        });
        
      })
      .catch(error => {
        $scope.isLoading           = false;
        $scope.isLoginCredsInvalid = true;
        logService.console.error(error);
      });
  };

  // From lock screen
  $scope.relogUser = (form) => {
    // Validate Form
    if (form.$invalid) {
      return;
    }
    $scope.loginInfo.username = $localStorage.authUser.username;
    $scope.loginInfo.password = form.password.$viewValue;
    $scope.loginClick(true);
  };

  $scope.signUpClick = (form) => {

    noty.closeAll();

    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    //Validate Username
    var valid = true;
    if ($scope.signupInfo.username.length <= 3) {
      let alert = "<p>Username is <b class='noty-bold'>too short</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }
    if ($scope.signupInfo.username.length >= 15) {
      let alert = "<p>Username is <b class='noty-bold'>too long</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }
    if ($scope.signupInfo.username.indexOf(' ') >= 0) {
      let alert = "<p>Username cannot contain <b class='noty-bold'>white space</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    //Validate PWs
    if ($scope.signupInfo.password.length < 8) {
      let alert = "<p>Passwords must be at least <b class='noty-bold'>8 characters</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[a-z]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one lowercase (a-z)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[A-Z]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one uppercase (A-Z)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!(/[0-9]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one digit (0-9)</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }
    if (!(/[~`!#$%\^&*+=\-\[\]\\';@,/{}|\\":<>\?]/g.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one non-alphanumeric character</b></p>";
      new noty({
        text   : alert,
        timeout: false,
        type   : 'warning',
      }).show();
      valid = false;
    }

    if (!valid) {
      return false;
    }

    if ($scope.signupInfo.password != $scope.signupInfo.cPassword) {
      let alert = "<h5>Passwords must match.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      $scope.invalidPW = true;
      return false;
    }

    $scope.isLoading = true;

    $scope.signup()
      .then((result) => {

        $localStorage.access_token = result.user.token;
        logService.console.log("Auth User Created");

        // Create the facility user
        $scope.createRehabUser(result.user)
          .then((result) => {
            logService.console.log("Rehab User Created");
            $scope.isLoading = false;

            $scope.loginInfo.username = result.username;
            $scope.goToSignupConfirmation();
          })
          .catch((error) => {
            logService.console.error(error);
            $scope.isLoading = false;

            if (error.errorMessage) {
              new noty({
                text: error.errorMessage
              }).show();

            }
          });
      })
      .catch((error) => {
        logService.console.error(error);
        $scope.isLoading = false;

        if (error.errorMessage) {
          new noty({
            text: error.errorMessage
          }).show();

        }
      });

  };

  $scope.forgotUsernameSubmit = (form) => {
    noty.closeAll();
    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    $scope.isLoading = true;

    $scope.forgotUsernamePost()
      .then(response => {
        logService.console.info("Forgot Username Response", response);
        $scope.goToForgotUsernameConfirm();
        $scope.isLoading = false;
      })
      .catch(error => {
        logService.console.error(error);
        $scope.isLoading = false;
      });
  };

  $scope.forgotPasswordSubmit = (form) => {
    noty.closeAll();

    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    $scope.isLoading = true;

    $scope.forgotPasswordPost()
      .then(response => {
        logService.console.info("Forgot Password Response", response);
        $scope.goToForgotPasswordConfirm();
        $scope.isLoading = false;
      })
      .catch(error => {
        logService.console.error(error);
        $scope.isLoading = false;
      });
  };

  $scope.signupNextClick = () => {
    $scope.goToUserRole();
  };

  $scope.facilitySelected = (facility) => {

    $scope.rehabDetails.facilities.forEach(fac => {
      fac.isSelected = false;
    });

    facility.isSelected = true;
    $scope.facilityId   = facility.rehabFacilityId;

    $timeout(() => {
      $scope.goToSignup();
    }, 750);
  };

  /***************************
   * CRUD
   **************************/
  //POST
  $scope.loginUser = () => {
    let params = {
      "username" : $scope.loginInfo.username,
      "password" : $scope.loginInfo.password,
      "rehab_key": $scope.loginInfo.subdomain,
      "domainName": $scope._Subdomain
    };

    return allevaApi.Auth.login(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.forgotUsernamePost = () => {
    let params = {
      "email": $scope.forgotUsername.email,
      "rehabSubdomain": $scope._Subdomain,
      "rehabName": $scope.rehabDetails.rehabName,
      "rehabEmail": $scope.rehabDetails.supportEmail
    };

    return allevaApi.Auth.forgotUsername(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.forgotPasswordPost = () => {
    let params = {
      "email"         : $scope.forgotPassword.email,
      "rehabSubdomain": $scope._Subdomain,
      "rehabName"     : $scope.rehabDetails.rehabName,
      "rehabEmail"    : $scope.rehabDetails.supportEmail
    };

    return allevaApi.Auth.forgotPassword(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.signup = () => {
    let params = {
      "username" : $scope.signupInfo.username,
      "password" : $scope.signupInfo.password,
      "email"    : $scope.signupInfo.create_email,
      "firstName": $scope.signupInfo.fName,
      "lastName" : $scope.signupInfo.lName,
      "role"     : $scope.signupInfo.roleName,
      "domainName": $scope._Subdomain
    };

    return allevaApi.Auth.signup(params)
      .then((response) => {
        if (response.user) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      });

  };

  $scope.createRehabUser = (user) => {
    let params = {
      "ApplicationUserId": user.id,
      "Username"         : user.username,
      "Email"            : user.email,
      "FirstName"        : user.firstName,
      "LastName"         : user.lastName,
      "Role"             : $scope.signupInfo.role,
      "RehabId"          : $scope.rehabDetails.rehabId,
      "FacilityId"       : $scope.facilityId,
      "RehabName"        : $scope._Subdomain,
      "CreatedBy"        : -2, // Family app default
      "CreatedDate"      : new Date(),
      "IsActive"         : true,
      "fromInvite"       : $scope.fromInvite,
      "fromPortal"       : true
    };

    return allevaApi.FamilyAPI.createRehabUser(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  /***************************
   * Navigation
   **************************/
  $scope.resetViews = () => {
    $scope.isLogin                 = false;
    $scope.isSignup                = false;
    $scope.isUserRole              = false;
    $scope.isSignupConfirmation    = false;
    $scope.isSelectFacility        = false;
    $scope.isForgotPassword        = false;
    $scope.isForgotPasswordConfirm = false;
    $scope.isForgotUsername        = false;
    $scope.isForgotUsernameConfirm = false;
  };

  $scope.goToLogin = () => {
    noty.closeAll();
    $scope.resetViews();
    if (angular.isDefined($scope.forgotUsername)) {
      $scope.forgotUsername = {};
    }
    if (angular.isDefined($scope.forgotPassword)) {
      $scope.forgotPassword = {};
    }
    $scope.isLogin = true;
  };

  $scope.goToSignup = () => {

    if ($location.search().email) {
      $scope.fromInvite = true;
    } else {
      $scope.fromInvite = false;
    }
    $scope.signupInfo.fName        = $location.search().fname;
    $scope.signupInfo.lName        = $location.search().lname;
    $scope.signupInfo.create_email = $location.search().email;

    $scope.resetViews();
    $scope.isSignup = true;

  };

  $scope.goToSelectFacility = () => {

    $scope.facilityId = $location.search().facility;
    if ($scope.facilityId) { // Bypass facility selection
      $scope.goToSignup();
      return;
    }

    $scope.resetViews();
    $scope.isSelectFacility = true;
  };

  $scope.goToUserRole = () => {
    $scope.forMyselfSelected    = false;
    $scope.familyMemberSelected = false;

    $scope.resetViews();
    $scope.isUserRole = true;
  };

  $scope.goToSignupConfirmation = () => {
    $scope.resetViews();
    $scope.isSignupConfirmation = true;
  };

  $scope.goToForgotPassword = () => {
    $scope.resetViews();
    $scope.isForgotPassword = true;
  };

  $scope.goToForgotUsername = () => {
    $scope.resetViews();
    $scope.isForgotUsername = true;
  };

  $scope.goToForgotPasswordConfirm = () => {
    $scope.resetViews();
    $scope.isForgotPasswordConfirm = true;
  };

  $scope.goToForgotUsernameConfirm = () => {
    $scope.resetViews();
    $scope.isForgotUsernameConfirm = true;
  };

  /***************************
   * Helpers
   **************************/
  $scope.toggleRole = (role) => {
    noty.closeAll();

    $scope.signupInfo.role     = role.id;
    $scope.signupInfo.roleName = role.name;

    $timeout(() => {
      $scope.goToSelectFacility();
    }, 750);
  };

}
