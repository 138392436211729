/*jshint esversion: 6 */

/*
Paginate Model:
{
    totalItems : // Total number of items to paginate
    itemsPerPage : // User selected number of items per page
    currentPage : // current page of pagination
}
*/
PaginationController.$inject = [];
export default function PaginationController() {

    this.initPagination = () => {
        this.model.currentPage = 1;
        this.model.totalItems = this.items;
        this.model.itemsPerPage = "10";
    }

    // returns the number of pages in the pagination control
    this.numberOfPages = (paginateModel) => {
        return Math.ceil(paginateModel.totalItems/parseInt(paginateModel.itemsPerPage));
    };

    // selected page # or hit previous or next
    this.selectPage = (pageNum, paginateModel) => {
        if(pageNum == 0 || pageNum > this.numberOfPages(paginateModel)){
            // ignore if page is 0 or higher than the page count
            return;
        }
        paginateModel.currentPage = pageNum;
        return paginateModel;
    };

    // selected how many items per page for pagination
    this.changeItemsPerPage = (paginateModel) => {
        paginateModel.currentPage = 1;
    };
}