DarkDeploy.$inject = ['$window', '$scope',];
export default function DarkDeploy($window, $scope) {

    const subdomain = $scope._Subdomain;

    const localDomains = ".local";
    const devDomains = "development";
    const allevaDomains = "alleva"; // includes staging and production
    const stagingDomain = "allevasoftrehab";

    const show = true;
    const hide = false;

    // Hide from Client Rehabs only.
    // Show for local + development + staging + Alleva/AllevaDemo (prod)
    const clients = () => {
        if($window.location.href.includes(`${localDomains}`) || subdomain.includes(`${allevaDomains}`) || subdomain == devDomains){
            return show;
        }
        return hide;
    }

    // Show for local environment only
    // Hide from ALL staging and production rehabs
    const development = () => {
        if($window.location.href.includes(`${localDomains}`)){
            return show;
        }
        return hide;
    }

    // Show for local + development + staging environments
    // Hide from ALL production rehabs
    const staging = () => {
        if($window.location.href.includes(`${localDomains}`) || subdomain == stagingDomain || subdomain == devDomains){
            return show;
        }
        return hide;
    }

    // Show for staging environment only
    // Hide from ALL local/development and production rehabs
    const stagingOnly = () => {
        if(subdomain == stagingDomain){
            return show;
        }
        return hide;
    }
    
    /******************/
    /* Custom Domains */
    /******************/
    //Hide for all rehabs except for 'domainName'.
    this.except = (domainName) => {
        if(subdomain == domainName){
            return show;
        }
        return hide;
    }

    //Hide only for 'domainName'.
    this.for = (domainName) => {
        if(subdomain == domainName){
            return hide;
        }
        return show;
    }

    this.everywhere = hide;
    this.clients = clients();
    this.development = development();
    this.staging = staging();
    this.stagingOnly = stagingOnly();

    return this;
};