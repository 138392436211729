/*jshint esversion: 6 */

GClassroomService.$inject = ['allevaApi'];
export default function GClassroomService(allevaApi) {
    this.authorizeGoogleClassrooms = () => {
        return allevaApi.Classrooms.authenticate()
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.isAuthenticated = () => {
        return allevaApi.Classrooms.isAuthenticated()
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.unAuthenticate = () => {
        return allevaApi.Classrooms.unAuthenticate()
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.getCourses = () => {
        return allevaApi.Classrooms.getCourses()
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.inviteStudent = (invite) => {
        return allevaApi.Classrooms.inviteStudent(invite)
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.inviteGuardian = (invite) => {
        return allevaApi.Classrooms.inviteGuardian(invite)
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };

    this.getClientCourses = (userId) => {
        return allevaApi.Classrooms.getClientCourses(userId)
            .then((result) => {
                return Promise.resolve(result);
            }).catch(error => {
                return Promise.reject(error);
            });
    };
}
