/*jshint esversion: 6 */

export default function LogService() {
    const debugLogging = ALLEVA_DEBUG_LOGGING; // injected by webpack

    return {
        console: {
            log(message, forceWrite = false) {
                if(debugLogging || forceWrite) { console.log(message); }
            },
            info(title, messages){
                if(debugLogging) { 
                    var args = Array.prototype.splice.call(arguments, 1);
                    console.info(title, args); 
                }
            },
            error(message){
                console.log("------------------------ Error captured: ------------------------");
                console.log(message);
                console.log("------------------------ Error captured END ------------------------");
            }
        }
    };
}
