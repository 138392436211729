InboxController.$inject = ['logService', '$scope', 'allevaApi', '$timeout', 'noty', 'moment', '$localStorage'];
export default function InboxController(logService, $scope, allevaApi, $timeout, noty, moment, $localStorage) {
  /***************************
   * Params
   **************************/
  // Nav

  // Table Pagination
  $scope.paginateSentList = {};
  $scope.paginateReadList = {};
  $scope.paginateAttachList = {};

  /***************************
   * Actions
   **************************/
 


  /***************************
   * CRUD
   **************************/
  // GET
 

  // POST
 
  /***************************
   * Uploader
   **************************/
 

  /***************************
   * Froala Editor
   **************************/
 
  /***************************
   * Navigation
   **************************/

  /***************************
  * Pagination
  **************************/
  $scope.pagination = (totalItems, itemsPerPage) => {
      // returns the number of pages in the pagination control
      return Math.ceil(totalItems/parseInt(itemsPerPage));
  };

  $scope.selectSentPageNum = (pageNum) => {
    // selected page # or hit previous or next
    if(pageNum == 0 || pageNum > $scope.pagination($scope.currentClient.emails.inbox.sentEmails.length, $scope.sentItemsPerPage)){
      // ignore if page is 0 or higher than the page count
      return;
  }
      $scope.currentSentPage = pageNum;
  };

  $scope.sentItemsPerPageChanged = (items) => {    
    // selected how many items per page for pagination
    $scope.sentItemsPerPage = items;    
    $scope.currentSentPage = 1;
  };

  /***************************
   * Helpers
   **************************/
 
}
